import pdProtection from '../../../assets/files/ru/personal_data_processing_policy.pdf';
import pdListOfThirdParties from '../../../assets/files/ru/personal_data_processing_policy_04_03_2025.pdf';

export default {
    seo: {
      title: 'Защита ПД | Банк 131',
      description: 'Защита ПД'
    },
    title: 'Защита ПД',
    content: [
        {
          label: 'Дата размещения 04.03.2025',
          title: 'Перечень третьих лиц, которые осуществляют обработку персональных данных клиентов на основании заключенных ими с АО «Банк 131» договоров, согласий клиентов.PDF',
          link: pdListOfThirdParties
        },
        {
          label: 'Дата размещения 19.09.2023',
          title: 'Политика АО «Банк 131» в отношении обработки персональных данных.PDF',
          link: pdProtection
        }
    ]
}