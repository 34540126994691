import pdProtection from '../../../assets/files/en/personal_data_processing_policy.pdf';

export default {
    seo: {
        title: 'PD protection | Bank 131',
        description: 'PD protection'
    },
    title: 'PD protection',
    content: [
         {
             label: 'Posting date 15.04.2021',
             title: 'Bank 131 JSC Personal Data Processing Policy.PDF',
             link: pdProtection
         }
     ]
}