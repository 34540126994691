import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';

import RectangleLink from "../../components/RectangleLink";

import enMessages from '../../i18n/legal/pdProtection/en.js'
import ruMessages from '../../i18n/legal/pdProtection/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const PdProtectionPage = ({ pathContext: { locale } }) => {
  const section = 'pdProtection';

  return (
    <TemplateLegalPage
      locale={locale}
      section={section}
      messages={messages[locale]}
      messagesMain={messagesMain[locale]}
    >
      {messages[locale].content.map((item) => {
        return (
          <RectangleLink
            key={item.title}
            label={item.label}
            link={item.link}
            target="_blank"
            title={item.title}
          />
        );
      })}
    </TemplateLegalPage>
  );
};

export default PdProtectionPage;
